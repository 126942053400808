import React, { useContext, useEffect} from "react";
import { EventContext } from "../Providers/EventProvider";

export default function EventReview() {
  const { events } = useContext(EventContext);

  return (
    <div className="events-review">
      {events.map((event, index) => (
        <div key={index} className="event-review">
          <h3 className="event-review-name">{event.name}</h3>
          {event.people.map((person, index) => (
            <div key={index} className="event-person-review">
              {person.isComing ? (
                  <div className="event-review-person-name">{person.Name}: Is Coming</div>
              ) : (
                <div className="event-review-person-name">{person.Name}: Is Not Coming</div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
