import React, { useEffect, useContext, useState } from "react";
import { PartyContext } from "../Providers/PartyProvider";
import { Button, Container } from "reactstrap";
import SelectSearch from "react-select-search";
import "react-select-search/style.css";
import "../styles/RSVP.css";
import "react-slidy/lib/styles.css";
import {  useNavigate } from "react-router-dom";


function RSVP() {
  const { parties, getParties, setParty } = useContext(PartyContext);
  

  const [partyId, setPartyId] = useState();

  useEffect(() => {
    getParties();
  }, []);

  const options = parties.map((party) => ({
    name: party.name,
    value: party._id,
  }));

  const getParty = () => {
    if(partyId){
      return parties.find(party => party._id === partyId)
    }
  }

  const navigate = useNavigate();

  const goToRSVPForm =() => {
    setParty(getParty())
    navigate(`/RSVP/Form`);
  }

  return (
    <Container className="rsvp-container">
      <SelectSearch
        options={options}
        value={partyId}
        name="Parties"
        placeholder="Choose your party"
        search="true"
        onChange={setPartyId}
      />
      {partyId ? (
         <Button onClick={goToRSVPForm}>
         Continue
       </Button>
      ) : <></>}
    </Container>
  );
}

export default RSVP;
