import React from "react";
import "../styles/Registry.css";

export default function Registry() {
  return (
    <div id="registry">
      <h1>Registry</h1>
      <h2>
        Your presence at our wedding is truly all we desire. Gifts are not
        necessary, but if you insist, we kindly suggest contributing towards our <a href="https://registry.theknot.com/lilia-merbouche-brady-logan-july-2024-tn/59349956">
          future adventures and the memories we'll create. </a> 
         Your presence alone is the greatest gift of all, and we're grateful to
        share this special day with you.
      </h2>
    </div>
  );
}
