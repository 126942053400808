import React, {createContext, useState } from "react";
import Login from "../Components/Login";

export const LoginContext = createContext();

export function  LoginProvider(props) {

    const [loggedIn, setLoggedIn] = useState(sessionStorage.getItem("loggedIn"))

    const login = async (pw) => {
        try {
            console.log("Logging In");
            const response = await fetch(`/api/login`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(pw),
            });
            if (response.ok) {
              const jsonData = await response.json();
              if(jsonData.login)
              {
                sessionStorage.setItem("loggedIn", true)
                setLoggedIn(true)
              }
              else {
                throw new Error("Invalid Login")
              }
            }
          } catch (error) {
            throw new Error("Error logging in:", error);
          }
    }


    return (
        <LoginContext.Provider value={{ login }}>
            {loggedIn
            ? props.children
            : <Login />}
        </LoginContext.Provider>
    )
}