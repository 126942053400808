import React, {createContext, useState } from "react";

export const EventContext = createContext();

export function  EventProvider(props) {

    const [events, setEvents] = useState([])

    const getEvents = async (id) => {
        try {
            console.log("Getting Events");
            const response = await fetch(`/api/fullEvents/${id}`);
            if(response.ok)
            {
                const jsonData = await response.json();
                setEvents(jsonData);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    return (
        <EventContext.Provider value={{ events, getEvents, setEvents }}>
            {props.children}
        </EventContext.Provider>
    )
}