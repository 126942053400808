import React, { useState, useEffect, useRef, useCallback } from "react";
import "../styles/ImageCarousel.css";

const ImageCarousel = function ImageCarousel({ speed, direction, loaded, children }) {
  const [looperInstances, setLooperInstances] = useState(1);
  const outerRef = useRef(null);
  const innerRef = useRef(null);

  function resetAnimation() {
    if (innerRef.current) {
      innerRef.current.setAttribute("data-animate", "false");

      setTimeout(() => {
        if (innerRef.current) {
          innerRef.current.setAttribute("data-animate", "true");
        }
      }, 10);
    }
  }

  const setupInstances = useCallback(() => {
    if (!innerRef.current || !outerRef.current || !loaded) return;

    const { width } = innerRef.current.getBoundingClientRect();
    const { width: parentWidth } = outerRef.current.getBoundingClientRect();

    const widthDeficit = parentWidth - width;
    const instanceWidth = width / innerRef.current.children.length;

    if (widthDeficit && instanceWidth) {
      setLooperInstances(
        parseInt(looperInstances + Math.ceil(widthDeficit / instanceWidth) + 1)
      );
    }
    resetAnimation();
  }, [looperInstances, loaded]);

  useEffect(() => setupInstances(), [setupInstances]);

  useEffect(() => {
    window.addEventListener("resize", setupInstances);

    return () => {
      window.removeEventListener("resize", setupInstances);
    };
  }, [looperInstances, setupInstances]);

  return (
    <div className="carousel" style={{display: loaded ? 'flex' : 'none'}}>
      <div className="looper" ref={outerRef}>
        <div className="looper__innerList" ref={innerRef} data-animate="true">
          {Array.from({length: looperInstances})
            .map((looperInstance, index) => (
              <div
                key={index}
                className="looper__listInstance"
                style={{
                  animationDuration: `${speed}s`,
                  animationDirection:
                    direction === "right" ? "reverse" : "normal",
                }}
              >
                {children}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default ImageCarousel;
