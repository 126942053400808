import React, { useState, useContext} from "react";
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import { Container } from "reactstrap";
import "../styles/Login.css"
import { LoginContext } from "../Providers/LoginProvider";

export default function Login(){

    const { login } = useContext(LoginContext);

    const [password, setPassword] = useState();

    const loginSubmit = (e) => {
        e.preventDefault();
        login(password)
            .catch(() => alert("Error Logging In"));
    };

    return (
        <Container className="login-form-container">
            <Form onSubmit={loginSubmit} className="login-form">
                <fieldset>
                    <FormGroup>
                        <Label for="password">Password</Label>
                        <Input id="password" type="password" onChange={e => setPassword(e.target.value)} />
                    </FormGroup>
                    <FormGroup>
                        <Button>Login</Button>
                    </FormGroup>
                </fieldset>
            </Form>
        </Container>

    );
}