import React, { useState, useEffect } from "react";
import { Button, Spinner } from "reactstrap";
import { useNavigate } from "react-router-dom";
import ImageCarousel from "./ImageCarousel";
import CountdownClock from "./CountdownClock";
import Info from "./Info";
import Travel from "./Travel";
import FAQ from "./FAQ";
import OurPeople from "./OurPeople";
import Registry from "./Registry";
import WeddingWeekend from "./WeddingWeekend";
import Credit from "./Credit";

function Hero() {
  const [loaded, setLoaded] = useState(false);
  const navigate = useNavigate();
  var loadedImages = 0;

  const importAll = (r) => {
    let images = [];
    let keys = [...r.keys()]
    shuffle(keys)
    keys.map((item) => {
      images.push(r(item));
    });
    return images;
  };

  const shuffle = (array) => {
    let currentIndex = array.length;
  
    // While there remain elements to shuffle...
    while (currentIndex != 0) {
  
      // Pick a remaining element...
      let randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
  
      // And swap it with the current element.
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex], array[currentIndex]];
    }
  }

  var images = importAll(
    require.context("../img/yes", false, /\.(png|jpe?g|svg)$/));

 

  const handleLoad = () => {
    loadedImages++;
    if (loadedImages == images.length) {
      setLoaded(true);
    }
  };

  return (
    <div className="App">
      {!loaded && (
        <div className="spinner-container">
          <Spinner>Loading...</Spinner>
        </div>
      )}

      <ImageCarousel speed="70" direction="right" loaded={loaded}>
        {images.map((image, index) => (
          <div key={index} className="contentBlock">
            <img src={image} alt={`Image ${index}`} onLoad={handleLoad} />
          </div>
        ))}
      </ImageCarousel>
      <div id="main-section">
        <Info />
        <CountdownClock targetDate="2024-07-20T00:00:00" />
        <div className="rsvp-button-container">
          <Button className="rsvp-button" onClick={() => navigate("/RSVP")}>
            RSVP
          </Button>
        </div>
        <WeddingWeekend />
        <Travel />
        <FAQ />
        <OurPeople />
        <Registry />
        <Credit />
      </div>
    </div>
  );
}

export default Hero;
