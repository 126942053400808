import React, {useContext} from "react";
import { DietaryRestrictionsContext } from "../Providers/DietaryRestrictionsProvider";

export default function DietaryRestrictionsReview() {

    const {restrictions} = useContext(DietaryRestrictionsContext)

    return (
        <div className="dietary-restrictions-review">
            <h2>Dietary Restrictions</h2>
            {restrictions.map((restriction, index) => (
                <div key={index} className="restriction-review">{restriction}</div>
            ))}
        </div>
    )
}