import React, {useContext}  from "react";
import { Input, Label } from "reactstrap";
import { PartyContext } from "../Providers/PartyProvider";
import '../styles/ContactInfo.css'

export default function ContactInfo() {

    const {party, setParty} = useContext(PartyContext)

    const updatePartyPhone = (event) => {
        const partyCopy = {...party}
        partyCopy.phone = event.target.value
        setParty(partyCopy)
    }

    const updatePartyEmail = (event) => {
        const partyCopy = {...party}
        partyCopy.email = event.target.value
        setParty(partyCopy)
    }

    return (
        <div className="contact-info">
            <h1>Contact Info</h1>
            <h2 className="contact-info-subtitle">Please provide us with the best information to reach you.</h2>
                <Label for="party-phone">Phone: </Label>
                <Input id="party-phone" type="phone" defaultValue={party.phone} onChange={updatePartyPhone} />
                
                <Label for="party-email">Email: </Label>
                <Input id="party-email" type="email" defaultValue={party.email} onChange={updatePartyEmail} />
        </div>
    )
}