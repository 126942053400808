import React, {useContext}  from "react";
import { PartyContext } from "../Providers/PartyProvider";

export default function ContactInfoReview() {

    const {party} = useContext(PartyContext)

    return (
        <div className="contact-info-review">
            <h2>Contact Info</h2>
                <div className="contact-info-review-phone">Phone: {party.phone}</div>
                <div className="contact-info-review-email">Email: {party.email} </div>
        </div>
    )
}