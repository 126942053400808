import React, {createContext, useState } from "react";

export const DietaryRestrictionsContext = createContext();

export function  DietaryRestrictionsProvider(props) {

    const [restrictions, setRestrictions] = useState([])

    const insertDietaryRestrictions = async (partyId) => {
      const restrictionsObjects = restrictions.map(restriction => {
        return {
          party: partyId,
          restriction: restriction
        }
      })
        try {
            console.log("Inserting Dietary Restrictions");
            const response = await fetch(`/api/dietaryRestrictions`, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(restrictionsObjects),
            });
            if (response.ok) {
              const jsonData = await response.json();
              console.log(jsonData);
            }
          } catch (error) {
            console.error("Error inserting data:", error);
          }
    }


    return (
        <DietaryRestrictionsContext.Provider value={{ restrictions, setRestrictions, insertDietaryRestrictions }}>
            {props.children}
        </DietaryRestrictionsContext.Provider>
    )
}