import React, { useEffect, useState, useContext, useRef } from "react";
import { Button, InputGroup, ButtonGroup, Input } from "reactstrap";
import { DietaryRestrictionsContext } from "../Providers/DietaryRestrictionsProvider";
import "../styles/DietaryRestrictions.css"

export default function DietaryRestrictions() {
  const [isEditing, setIsEditing] = useState(false);
  const [currentRestriction, setCurrentRestriction] = useState({
    index: -1,
    value: '',
  });
  const {restrictions, setRestrictions} = useContext(DietaryRestrictionsContext)
  const [disabled, setDisabled] = useState([])
  const inputRefs = useRef([])
  var isSaving = useRef(false)

  const handleUpdateCurrentRestriction = (e) => {
    var currentRestrictionClone = {...currentRestriction}
    currentRestrictionClone.value = e.target.value
    setCurrentRestriction(currentRestrictionClone);
  };

  useEffect(() => {
    if(currentRestriction.index !== -1)
    {
      setIsEditing(true)
    }
    else {
      setIsEditing(false)
    }
  }, [currentRestriction])

  useEffect(() => {
    if(isEditing)
    {
      if(currentRestriction.index === restrictions.length)
      {
        newRestriction();
      }
      else {
        handleSetEnabled();
      }
    }
  }, [isEditing])

  const newRestriction = () => {
    var restrictionsClone = [...restrictions];
    restrictionsClone.push("");
    setRestrictions(restrictionsClone);
  };


  useEffect(() => {
    if(!isSaving.current && isEditing && currentRestriction.index === restrictions.length - 1 )
    {
      handleNewDisabled()
    }
    else if (!isEditing)
    {
      syncRefs();
    }
    
  }, [restrictions])

  const syncRefs = () => {
    for(var i = 0; i <restrictions.length; i++)
    {
      inputRefs.current[i].value = restrictions[i]
    }
  }

  const handleNewDisabled = () => {
    var disabledClone = [...disabled]
    disabledClone.push(false)
    setDisabled(disabledClone)
  }

  const handleSetEnabled = () => {
    var disabledClone = [...disabled]
    disabledClone[currentRestriction.index] = false
    setDisabled(disabledClone)
  }

  const handleNewRestriction = () => {
    setCurrentRestriction({
      value: "",
      index: restrictions.length
    })
  }

  const handleEditRestriction = (e, restriction, index) => {
    e.stopPropagation();
    var newCurrentRestriction = {
      index: index,
      value: restriction,
    };
    setCurrentRestriction(newCurrentRestriction);
  };

  const handleCancelEditRestriction = () => {
    var input = document.getElementById(
      `diet-restriction-${currentRestriction.index}-${restrictions[currentRestriction.index]}`
    );
    input.value = restrictions[currentRestriction.index];
    isSaving.current = false
    handleSetDisabled()
  };

  const handleRestrictionSave = () => {
    isSaving.current = true
    var restrictionsClone = [...restrictions]
    restrictionsClone[currentRestriction.index] = currentRestriction.value
    setRestrictions(restrictionsClone)
    handleSetDisabled()
  };


  const handleSetDisabled = () => {
    var disabledClone = [...disabled]
    disabledClone[currentRestriction.index] = true
    setDisabled(disabledClone)
  }

  const handleRestrictionDelete = (index) => {
    var restrictionsClone = [...restrictions];
    restrictionsClone.splice(index, 1);
    setRestrictions(restrictionsClone);

    var disabledClone = [...disabled];
    disabledClone.splice(index, 1);
    setDisabled(disabledClone);
  }

  useEffect(() => {
    if(currentRestriction.index !== -1)
    {
      if(disabled[currentRestriction.index])
      {
        if(isSaving.current)
        {
          var restrictionsClone = [...restrictions]
          restrictionsClone[currentRestriction.index] = currentRestriction.value
          setRestrictions(restrictionsClone)
        }
        var newCurrentRestriction = {
          index: -1,
          value: '',
        };
        setCurrentRestriction(newCurrentRestriction);
      }
      else if(!disabled[currentRestriction.index])
      {
        inputRefs.current[currentRestriction.index].focus();
      }
    }
  }, [disabled])

  const handleKeyDown = (e) => {
   
    if(e.key === 'Enter')
    {
      e.stopPropagation();
      handleRestrictionSave();
    }
  }

  return (
    <div className="dietary-restrictions-form">
      <h1 className="dietary-restrictions-title">Dietary Restrictions</h1>
      {restrictions ? restrictions.map((restriction, index) => (
          <InputGroup className="input-dietary-restriction" key={index}>
            <Input
              onChange={handleUpdateCurrentRestriction}
              innerRef={element => inputRefs.current[index] = element}
              id={`diet-restriction-${index}-${restriction}`}
              disabled={disabled[index]}
              onKeyDown={handleKeyDown}
            />
            <Button
              disabled={isEditing}
              onClick={(e) => {
                handleEditRestriction(e, restriction, index);
              }}
            >
              Edit
            </Button>
            <Button
              disabled={isEditing}
              onClick={(e) => {
                handleRestrictionDelete(index);
              }}
            >
              Delete
            </Button>
          </InputGroup>
      )): <></>}
      {!isEditing ? <Button onClick={handleNewRestriction}>Add</Button> : <></>}
      {isEditing ? (
        <ButtonGroup>
          <Button onClick={handleRestrictionSave}>Save</Button>
          <Button onClick={handleCancelEditRestriction}>Cancel</Button>
        </ButtonGroup>
      ) : (
        <></>
      )}
    </div>
  );
}
