import React, { useContext, useState, useEffect } from "react";
import { Form, Container } from "reactstrap";
import { EventContext } from "../Providers/EventProvider";
import { PartyContext } from "../Providers/PartyProvider";
import ContactInfo from "./ContactInfo";
import ReactSlidy from "react-slidy";
import Event from "./Event";
import DietaryRestrictions from "./DietaryRestrictions";
import Review from "./Review";
import "../styles/RSVPForm.css";

const createStyles = (isActive) => ({
  background: "transparent",
  border: 0,
  color: isActive ? "#333" : "#ccc",
  cursor: "pointer",
  fontSize: "32px",
});

export default function RSVPForm() {
  const { getEvents, events } = useContext(EventContext);
  const { party } = useContext(PartyContext);

  const [actualSlide, setActualSlide] = useState(0);

  const updateSlide = ({ currentSlide }) => {
    setActualSlide(currentSlide);
  };

  const [slides, setSlides] = useState([]);

  const populateSlides = () => {
    const eventSlides = events.map((event, index) => (
      <Event
        key={event._id}
        event={event}
        eventIndex={index}
      />
    ));
    eventSlides.push(<ContactInfo key="contact" />);
    eventSlides.push(<DietaryRestrictions key="restrictions" />);
    eventSlides.push(<Review key="review"/>);
    setSlides(eventSlides);
  };

  useEffect(() => {
    getEvents(party._id);
  }, [party._id]);

  useEffect(() => {
    populateSlides();
  }, [events]);

  return (
    <Container className="rsvp-form-container">
      {party ? (
        <Form className="rsvp-form">
            <ReactSlidy
              doAfterSlide={updateSlide}
              slide={actualSlide}
              showArrows={true}
            >
              {slides}
            </ReactSlidy>
            <div className="Dots">
              {slides.map((_, index) => {
                return (
                  <button
                    key={index}
                    style={createStyles(index === actualSlide)}
                    onClick={() => updateSlide({ currentSlide: index })}
                  >
                    &bull;
                  </button>
                );
              })}
            </div>
        </Form>
      ) : (
        <></>
      )}
    </Container>
  );
}
