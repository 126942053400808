import React from "react";
import Person from "./Person";

function Event({ event, eventIndex }) {

  return (
    <div className="event">
      <div className="event-text">
        <div className="event-name">{event.name}</div>
        <div className="event-info">
          <div className="event-time">{event.time}</div>
          <div className="event-place"><a href={event.placeUrl}>{event.place}</a></div>
        </div>
        <div className="event-description">{event.description}</div>
        <div className="attending">Who will be joining us?</div>
        <div className="people">
          {event.people.map((person) => (
            <Person
              key={person._id}
              eventIndex={eventIndex}
              person={person}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Event;
