import React from "react";
import "../styles/Info.css";

function Info() {
  return (
    <div id="info-container">
      <div id="info">
        <div className="names">
          <div className="name">Brady</div>
          <div className="and">and</div>
          <div className="name">Lilia</div>
        </div>
        <div className="date-info">
          <div className="date">July 20, 2024</div>
          <div className="location">Nashville, TN</div>
        </div>
      </div>
    </div>
  );
}

export default Info;
