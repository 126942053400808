import React from "react";
import '../styles/Travel.css'

export default  function Travel () {
    return (
        <div id="travel">
            <h1>Travel</h1>
            <div className="travel-sections">
                <div className="travel-section">
                    <h2>
                        Hotel Block
                    </h2>
                    <p>
                        Please follow this <a href="https://book.passkey.com/go/LoganWedding2024">link</a> for the hotel block that we have reserved.
                    </p>
                </div>
                <div className="travel-section">
                    <h2>
                        Transportation
                    </h2>
                    <p>
                        We're working on arranging transportation.  Please check back closer to the wedding.
                    </p>
                </div>
                <div className="travel-section">
                    <h2>
                        Our Favorite Places
                    </h2>
                    <p>
                        A curated <a href="https://docs.google.com/document/d/1gHLJZcQq0zIrNRkbVW-qWU9itGDqDorpr7Jqm5nIR-Q/edit">list</a> of all the best things to do and eat in Nashville.  Continuously updated by the groom's amazing sister, Jacqui Logan.
                    </p>
                </div>
            </div>
        </div>
    )
}