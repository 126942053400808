import React, {createContext, useState } from "react";

export const PartyContext = createContext();

export function  PartyProvider(props) {

    const [parties, setParties] = useState([]);

    const [party, setParty] = useState()

    const getParties = async () => {
        try {
            console.log("Getting Parties");
            const response = await fetch(`/api/parties`);
            if(response.ok)
            {
                const jsonData = await response.json();
                setParties(jsonData);
            }
        }
        catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    // const getParty = async (id) => {
    //     try {
    //         console.log("Getting Party");
    //         const response = await fetch(`/api/parties/${id}`);
    //         if(response.ok)
    //         {
    //             const jsonData = await response.json();
    //             setParty(jsonData)
    //         }
    //     }
    //     catch (error) {
    //         console.error('Error fetching data:', error);
    //     }
    // }

    const updateParty = async () => {
        try {
          console.log("Updating Party");
          const response = await fetch(`/api/parties`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify([party]),
          });
          if (response.ok) {
            const jsonData = await response.json();
            console.log(jsonData);
          }
        } catch (error) {
          console.error("Error updating data:", error);
        }
      };


    return (
        <PartyContext.Provider value={{ party, getParties, parties, setParty, updateParty }}>
            {props.children}
        </PartyContext.Provider>
    )
}