import React, { useState, useEffect } from "react";
import "../styles/CountdownClock.css";

const CountdownClock = ({ targetDate }) => {
  const calculateTimeLeft = () => {
    const difference = +new Date(targetDate) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const { days, hours, minutes, seconds } = timeLeft;

  return (
    <div className="countdown-clock-container">
      <div className="countdown-clock">
        {days > 0 && (
          <div>
            <span>{days}</span> {days === 1 ? "day" : "days"}
          </div>
        )}
        {hours > 0 && (
          <div>
            <span>{hours}</span> {hours === 1 ? "hour" : "hours"}
          </div>
        )}
        {minutes > 0 && (
          <div>
            <span>{minutes}</span> {minutes === 1 ? "minute" : "minutes"}
          </div>
        )}
        <div>
          <span>{seconds}</span> {seconds === 1 ? "second" : "seconds"}
        </div>
        {days <= 0 && hours <= 0 && minutes <= 0 && (
          <div>Countdown expired</div>
        )}
      </div>
    </div>
  );
};

export default CountdownClock;
