import React from "react";
import '../styles/OurPeople.css'

export default  function OurPeople () {
    return (
        <div id="ourPeople">
            <h1>Our People</h1>
            <div className="ourPeople-sections">
                <div className="ourPeople-section">
                    <h2>The Parents</h2>
                    <div className="parent-section">
                        <h2>
                            Rachid and Louisa Merbouche
                        </h2>
                        <img className='our-people-image' src={'/img/RachidAndLouisa.jpg'} alt='Rachid and Louisa' />
                    </div>
                    <div className="parent-section">
                        <h2>
                            Jim and Kim Logan
                        </h2>
                        <img className='our-people-image' src={'/img/JimAndKim.jpg'} alt='Jim and Kim' />
                    </div>
                </div>
                <div className="ourPeople-section">
                    <h2>The Bridal Party</h2>
                    <div className="bridal-party-section">
                        <h2>
                            Sonia Merbouche
                        </h2>
                        <img className='our-people-image' src={'/img/Sonia.jpeg'} alt='Sonia Merbouche' />
                        <h3>
                            Maid of Honor & Sister of the Bride
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            JC Logan
                        </h2>
                        <img className='our-people-image' src={'/img/JC.jpg'} alt='JC Logan' />
                        <h3>
                            Best Man & Brother of the Groom
                        </h3>
                    </div>
 
                    <div className="bridal-party-section">
                        <h2>
                            Samantha Logan
                        </h2>
                        <img className='our-people-image' src={'/img/Sam.jpg'} alt='Samantha Logan' />
                        <h3>
                            Bridesmaid and Sister-in-law of the Groom
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Clint Johnson
                        </h2>
                        <img className='our-people-image' src={'/img/Clint.jpg'} alt='Clint Johnson' />

                        <h3>
                            Groomsman & Brother-in-law of the Groom
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Jacqui Logan (And Mimosa)
                        </h2>
                        <img className='our-people-image' src={'/img/Jacqui.jpg'} alt='Jacqui Logan' />
                        <h3>
                            Bridesmaid & Sister of the Groom
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Cole Lenfest
                        </h2>
                        <img className='our-people-image' src={'/img/Cole.jpg'} alt='Cole Lenfest' />
                        <h3>
                            Groomsman
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Urjita Tendolkar
                        </h2>
                        <img className='our-people-image' src={'/img/Urjita.jpeg'} alt='Urjita Tendolkar' />
                        <h3>
                            Bridesmaid
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Nathan Winn
                        </h2>
                        <img className='our-people-image' src={'/img/Nathan.jpg'} alt='Nathan Winn' />
                        <h3>
                            Groomsman
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Sara Conwell
                        </h2>
                        <img className='our-people-image' src={'/img/Sara.jpg'} alt='Sara-Cathrine Conwell' />
                        <h3>
                            Bridesmaid
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                       
                        <h2>
                            Jonathan Taylor (JT)
                        </h2>
                        <img className='our-people-image' src={'/img/JT.jpg'} alt='Jonathan Taylor' />
                        <h3>
                            Groomsman
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Lindsay Martin
                        </h2>
                        <img className='our-people-image' src={'/img/Lindsay.jpg'} alt='Lindsay Martin' />
                        <h3>
                            Bridesmaid
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Joey Keville
                        </h2>
                        <img className='our-people-image' src={'/img/Joey.jpg'} alt='Lindsay Martin' />
                        <h3>
                            Junior Groomsman
                        </h3>
                    </div>
                    <div className="bridal-party-section">
                        <h2>
                            Bailey Logan
                        </h2>
                        <img className='our-people-image' src={'/img/Bailey.jpg'} alt='Lindsay Martin' />
                        <h3>
                            Dog of Honor
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    )
}