import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import EventReview from "./EventReview";
import ContactInfoReview from "./ContactInfoReview";
import DietaryRestrictionsReview from "./DietaryRestrictionsReview";
import { Button } from "reactstrap";
import { DietaryRestrictionsContext } from "../Providers/DietaryRestrictionsProvider";
import { EventPersonContext } from "../Providers/EventPersonProvider";
import { PartyContext } from "../Providers/PartyProvider";
import '../styles/Review.css'


export default function Review() {
    const navigate = useNavigate();

    const [isSubmiting, setIsSubmiting] = useState(false)

    const {insertDietaryRestrictions} = useContext(DietaryRestrictionsContext)
    const {updateEventsPeople} = useContext(EventPersonContext)
    const {updateParty, party} = useContext(PartyContext)

    const onSubmit = async () => {
        setIsSubmiting(true)
        await updateAllFormValues()
        navigate("/RSVP/Form/ThankYou");
        setIsSubmiting(false)
    }

    const updateAllFormValues = async () => {
        try{
            await insertDietaryRestrictions(party._id)
        }
        catch(e){
            console.log("Error inserting Dietary Restrictions")
        }
        try{
            await updateEventsPeople()
        }
        catch(e){
            console.log("Error updating EventsPeople")
        }
        try{
            await updateParty()
        }
        catch(e){
            console.log("Error updating the party")
        }
    }

    return (
        <div className="review">
            <h1>Review</h1>
            <div className="events-review">
                <h2>Events</h2>
                <EventReview />
            </div>
            <ContactInfoReview />
            <DietaryRestrictionsReview />
            <div className="button-container">
                <Button className="submit-rsvp-button" disabled={isSubmiting} onClick={onSubmit}>Submit</Button>
            </div>
        </div>
    )
}