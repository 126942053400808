import React, { useContext, useState } from "react";
import { Input, Label } from "reactstrap";
import { EventContext } from "../Providers/EventProvider";

function Person({ person, eventIndex }) {
  const [selectedOption, setSelectedOption] = useState(person.isComing ? 'Coming' : 'Not Coming');
  const {events, setEvents} = useContext(EventContext)

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    if(e.target.value === 'Coming')
    {
      updatePerson(true)
    }
    else {
      updatePerson(false)
    }
  };

  const updatePerson = (value) => {
    var eventsClone = [...events]
    var currentPerson = events[eventIndex].people.find((p) => p._id === person._id)
    currentPerson.isComing = value
    setEvents(eventsClone)
  };

  return (
    <div className="person">
      <Label className="person-name" check>
        {person.Name}
      </Label>
      <Input onChange={handleChange} type="select" value={selectedOption}>
          <option value='Coming'>Coming</option>
          <option value='Not Coming'>Not Coming</option>
      </Input>
        
    </div>
  );
}

export default Person;
