import React from "react";
import {Button} from "reactstrap"
import { useNavigate } from "react-router-dom";
import "../styles/ThankYou.css"

export default function ThankYou() {
    const navigate = useNavigate();
    return (
        <div id="thank-you-page">
            <h1>Thank You For Your Submission!</h1>
            <h2>If you made a mistake, you can go back and change your answers</h2>
            <Button className="rsvp-button" onClick={() => navigate("/RSVP")}>
            RSVP
          </Button>
        </div>
    )
}