import React from "react";
import { Route, Routes as Switch } from "react-router-dom";
import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";
import RSVP from "./Components/RSVP";
import RSVPForm from "./Components/RSVPForm";
import ThankYou from "./Components/ThankYou";

export const ApplicationViews = (props) => {
  return (
    <div id="outer-container">
      <main id="page-wrap">
        <Navbar />
        <Switch>
          <Route path="/" element={<Hero />} />
          <Route path="/RSVP" element={<RSVP />} />
          <Route path="/RSVP/Form" element={<RSVPForm />} />
          <Route path="RSVP/Form/ThankYou" element={<ThankYou />} />
        </Switch>
      </main>
    </div>
  );
};
