import React, { createContext, useContext } from "react";
import { EventContext } from "./EventProvider";

export const EventPersonContext = createContext();

export function EventPersonProvider(props) {

  const {events} = useContext(EventContext)

   const createEventsPeople = () => {
      return events.map((e) => {
        return e.people.map((p) => {
          return {
            _id: p.eventPersonId,
            isComing: p.isComing,
          };
        });
      }).flat()

  };

  const updateEventsPeople = async () => {
    const eventsPeople = createEventsPeople()
    try {
      console.log("Updating EventPerson");
      const response = await fetch(`/api/eventsPeople`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(eventsPeople),
      });
      if (response.ok) {
        const jsonData = await response.json();
        console.log(jsonData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <EventPersonContext.Provider
      value={{ updateEventsPeople }}
    >
      {props.children}
    </EventPersonContext.Provider>
  );
}
