import React, {useState} from 'react'
import  { Navbar as ReactNavbar, NavbarBrand, Nav, NavItem, NavLink, Collapse, NavbarToggler } from 'reactstrap';
import { useNavigate } from 'react-router-dom'
import '../styles/Navbar.css'


const Navbar = () => {

    const [isOpen, setIsOpen] = useState(false);
    const navigate = useNavigate();
    const toggle = () => setIsOpen(!isOpen);

    const handleNavigate = (path) => {
        navigate(path)
        setIsOpen(false)
    }

    return (
        <ReactNavbar expand='md' fixed='top'> 
            <NavbarBrand >
                <img
                    alt="logo"
                    src='/img/Wedding_Logo___1_-removebg-preview.png'
                    style={{
                    height: 40,
                    width: 40
                    }}
                />
            </NavbarBrand >
            <NavbarToggler onClick={toggle} />
            <Collapse isOpen={isOpen} navbar>
                <Nav className="me-auto" navbar>
                    <NavItem>
                        <NavLink onClick={() => handleNavigate('/')} to='/'>Home</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => handleNavigate('/RSVP')}>RSVP</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink  onClick={() => handleNavigate('/')} href='#wedding-weekend'>Wedding Weekend</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink  onClick={() => handleNavigate('/')} href='#travel'>Travel</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => handleNavigate('/')} href='/#faq'>FAQ</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => handleNavigate('/')} href='/#ourPeople'>Our People</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink onClick={() => handleNavigate('/')} href='/#registry'>Registry</NavLink>
                    </NavItem>
                </Nav>
            </Collapse>
        </ReactNavbar>
    )
}

export default Navbar;