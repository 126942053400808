import React from "react";
import '../styles/FAQ.css'


export default  function FAQ () {
    return (
        <div id="faq">
            <h1>FAQ</h1>
            <div className="faq-sections">
                <div className="faq-section">
                    <h2>
                        What is the dress code?
                    </h2>
                    <p>
                        We'd love to see our friends and family get dressed up for our big day.  Dress code is formal attire.  Ladies can wear floor length, tea length, or midi formal dresses.  We encourage ladies to wear bright flowery patterns as the wedding will be held in a garden.  We will be on grass for most of the ceremony, so be conscious of that when picking shoes.  Gentlemen can wear a suit and tie.
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        Can I bring a date/plus one?
                    </h2>
                    <p>
                        Due to limited space and us wanting to keep the evening intimate, we are only able to accommodate those guests that have been formally invited on your wedding invitation.  If you received a plus one, they will appear under your name when you RSVP. Thank you for understanding.
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        Are kids invited?
                    </h2>
                    <p>
                        In the same vein of limited space and wanting to keep the eveing intimate, only kids listed on your wedding invitation are invited.  Their names will show up when you RSVP. 
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        Will transportation be provided?
                    </h2>
                    <p>
                        We are planning on providing transportation to and from the wedding venue and the hotel where booked a block in.  We are currently ironing out those details, so please check again as we get closer to the date.
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        Where should I stay?
                    </h2>
                    <p>
                        We booked a block of rooms at the Gaylord Hotel.  Please check the travel section of the website for more information on how to book.
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        What if I don't RSVP in time?
                    </h2>
                    <p>
                        Our RSVP deadline is June 1st.  Unfortunately, we will not be able to accommodate you if you RSVP late.  We will need to provide our wedding planners and venue an exact guest count by a certain date.  We will send out reminders, so please be sure to RSVP as soon as possible.  We can't wait to see you there!
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        What time should I arrive at the ceremony?
                    </h2>
                    <p>
                        Please arrive no later than 15 minutes before the ceremony.  This will allow you time to sign the guest book and find your seat.  If you arrive after the start of the ceremony, you will not be able to be seated and will have to wait until cocktail hour to enter.
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        Can I take pictures?
                    </h2>
                    <p>
                        We are having an unplugged ceremony.  No phones or cameras.  The greatest gift you can give us is being fully present when we say "I do".  We have hired a very talented team of professionals to capture this moment for us.  And we promise to share the pictures as soon as we receive them.  After the ceremony, feel free to take as many photos and videos as you like!  A photo booth will also be present.
                    </p>
                </div>
                <div className="faq-section">
                    <h2>
                        Is there parking available?
                    </h2>
                    <p>
                        If you choose to drive, complimentary parking is available at the venue.  The venue will allow you to keep your car there the next day.  So please be as safe as possible.
                    </p>
                </div>
            </div>
        </div>
    )
}