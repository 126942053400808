import React from "react";
import '../styles/WeddingWeekend.css'

export default  function WeddingWeekend () {
    return (
        <div id="wedding-weekend">
            <h1>Wedding Weekend</h1>
            <div className="wedding-weekend-sections">
                <div className="wedding-weekend-section">
                    <h2>
                        Welcome Party
                    </h2>
                    <p>
                        Friday 7/19 8-10:30 pm
                    </p>
                    <p>
                        Attire: Business Casual
                    </p>
                    <p>
                        <a href="https://www.adelesrestaurant.com/location/adeles-nashville/">Adele's Nashville</a>
                    </p>
                    <p className="description">
                        Join us for a delightful evening of celebration at our welcome party!  Get ready to kick off our wedding festivities with desserts, drinks, and dessert drinks as we get ready to toast the joyous days ahead.
                        We can't wait to welcome you and share this special moment with you.
                    </p>
                </div>
                <div className="wedding-weekend-section">
                    <h2>
                        The Wedding
                    </h2>
                    <p>
                        Saturday 7/20 4:45pm-11pm
                    </p>
                    <p>
                        Attire: Formal
                    </p>
                    <p>
                        <a href="https://www.hermitagegolf.com/">Hermitage Golf Course</a>
                    </p>
                    <p className="description">
                        The big day!  We are so excited to share this moment with you.  We ask that you please come and find your seats by 4:50pm, as the ceremony will start 
                        promptly at 5pm.  The ceremony will immediately be follwed by cocktail hour, dinner, and dancing!
                    </p>
                </div>
                <div className="wedding-weekend-section">
                    <h2>
                       After Party
                    </h2>
                    <p>
                        Saturday 7/20 11pm-Whenever we feel like it
                    </p>
                    <p className="description">
                        Keep the celebration going with a laid back after party following our wedding festivities!  Join us for a casual gathering at our onsite cabins for a night filled
                        with late night snacks and great drinks.  No RSVP necessary, just show up and have a good time.
                    </p>
                </div>
                <div className="wedding-weekend-section">
                    <h2>
                        Wedding Brunch
                    </h2>
                    <p>
                        Sunday 7/21 11am-2pm
                    </p>
                    <p>
                        Attire: Casual
                    </p>
                    <p>
                        <a href="https://www.sixtyvines.com/locations/nashville">Sixty Vines</a>
                    </p>
                    <p className="description">
                       Join us the morning after our wedding for a leisurely brunch celebration.  Stop by anytime between 11am and 2pm, this is a come and go event, so feel free 
                       to drop by whenever it suits you.  We can't wait to see you there!
                    </p>
                </div>
            </div>
        </div>
    )
}