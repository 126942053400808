import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ApplicationViews } from "./ApplicationViews";
import { PartyProvider } from "./Providers/PartyProvider";
import { EventProvider } from "./Providers/EventProvider";
import { EventPersonProvider } from "./Providers/EventPersonProvider";
import { LoginProvider } from "./Providers/LoginProvider";
import { DietaryRestrictionsProvider } from "./Providers/DietaryRestrictionsProvider";
import "./App.css";

function App() {
  return (
    <Router>
      <LoginProvider>
        <DietaryRestrictionsProvider>
          <EventProvider>
            <EventPersonProvider>
              <PartyProvider>
                <ApplicationViews />
              </PartyProvider>
            </EventPersonProvider>
          </EventProvider>
        </DietaryRestrictionsProvider>
      </LoginProvider>
    </Router>
  );
}

export default App;
